@import url("https://fonts.googleapis.com/css?family=Assistant|Merriweather&display=swap");

::selection {
  background-color: #3d85c6;
  color: #fff;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Assistant", sans-serif;
  background-color: rgb(249,250,251);
}

.title {
  font-family: "Merriweather", serif;
}

a {
  font-weight: bold;
  text-decoration: none;
  color: #3d85c6;
}

a:hover {
  color: #9fc5e8;
}

.btn {
  background-color: #6da7da;
  margin-top: 5px;
  padding: 6px;
  border: none;
  border-radius: 2px;
  font-family: "Assistant", sans-serif;
  font-size: 15px;
}

.btn a{
  color: #fff;
}

.btn:hover{
  cursor: pointer;
  background-color: #8ebae0;
}

.large-emoji {
  cursor: none;
  font-size: 120px;
  transition: transform 0.2s ease-in-out;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
}

.section {
  max-width: 100vw;
  margin: auto;
  padding: 25px;
  min-height: calc(45vh + 50px);
  font-size: 18px;
}

.section-title {
  font-weight: bold;
  font-family: "Merriweather", serif;
  color: #3d85c6;
  font-size: 32px;
}

/* HEADER */

header {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  position: fixed;
  background-color: rgba(249, 250, 251, 0.95);
  width: 100%;
}

#header-logo {
  font-size: 20px;
  padding-left: 20px;
}

.nav-item {
  margin: 5px;
  padding-right: 20px;
}

@media only screen and (max-width: 600px) {
  .nav-item {
    margin: 3px;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 500px) {
  #header-logo {
    display: none;
  }
  nav {
    margin: auto;
    font-size: 10px;
  }
}

/* END HEADER */

/* HOME */
#home {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./images/geometry2.png");
  font-size: 23px;
  font-family: "Merriweather", serif;
  font-weight: bold;
  line-height: 110%;
}

.home-emphasis {
  font-weight: lighter;
  letter-spacing: -.5px;
  font-family: "Assistant", sans-serif;
  font-size: 28px;
  color: #3d85c6;
}

#home-hand:hover {
  animation: wave 1.5s;
  animation-iteration-count: 1;
}

@keyframes wave {
  20% {
    transform: rotate(20deg);
  }
  70% {
    transform: rotate(-15deg);
  }
  100% {
    transform: none;
  }
}

@media only screen and (max-width: 500px) {
  #home {
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
  }
}

/* END HOME */

/* ABOUT */
#about {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  min-height: calc(50vh + 50px);
}

#about-title {
  text-align: left;
  font-size: 25px;
}

#about-headshot {
  max-width: calc(1/3);
  max-height: 275px;
  border-radius: 3px;
  box-shadow: 1px 1px 3px #888888;
}

#about-right {
  max-width: calc(2/3);
  margin: 25px;
  overflow: auto;
}

#about-skills {
  column-count: 2;
  -webkit-column-count: 2;
  -moz-column-count: 2;
}

@media only screen and (max-width: 600px) {
  #about {
    flex-wrap: wrap;
  }
  #about-title {
    padding-top: 10px;
  }
  #about-right {
    margin: auto;
  }
}

/* END ABOUT */

/* PROJECTS */

#projects {
  padding-top: 50px;
  max-width: 1200px;
}

.project-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.project-item:nth-child(even){
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  /* text-align: right; */
  /* list-style-p osition: inside; */
}

.project-desc-container {
  width: 100%;
}

.project-img-container {
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding-top: 50px;
  background-image: url("./images/geometry2.png");
  background-color: #f3f3f3;
  border-radius: 5px;
}

.project-img {
  max-width: 80%;
  height: auto;
}

.project-title {
  /* font-family: 'Merriweather', serif; */
  font-weight: bold;
  font-size: 24px;
  color: #3d85c6;
}

.project-tech ul{
  column-count: 2;
  -webkit-column-count: 2;
  -moz-column-count: 2;
}

@media only screen and (max-width: 600px) {
  .project-item {
    flex-wrap: wrap;
  }
  .project-img {
    align-self: center;
  }
}

/* END PROJECTS */

/* EXPERIENCE */

#experience {
  padding-top: 50px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.exp-item {
  margin: 15px 0;
}

.exp-title {
  display:inline;
  /* font-family: "Merriweather", serif; */
  font-weight: bold;
  color: #3d85c6;
  font-size: 20px;
}

.exp-loc {
  display:inline;
  font-weight: bold;
  color: #888888;
}

.exp-date {
  font-size: 14px;
}

.exp-desc {
  padding: 3px 0;
}

/* END EXPERIENCE */

/* CONTACT */

#contact {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./images/geometry2.png");
  font-size: 23px;
  font-family: "Merriweather", serif;
  font-weight: bold;
  line-height: 110%;
}

#mailbox {
  cursor: none;
  font-size: 120px;
}

#contact-body {
  display: flex;
  height: 100%;
  flex-direction: column;
}

#contact-btn {
  background-color: #6da7da;
  color: #FFF;
  margin-top: 5px;
  padding: 6px;
  border: none;
  border-radius: 2px;
  font-family: "Assistant", sans-serif;
  font-size: 15px;
}

.desc {
  font-family: "Assistant", sans-serif;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  #contact {
    flex-wrap: wrap;
    text-align: center;
  }
}

/* END CONTACT */